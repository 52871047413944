//frontend/solyo/components/AuthNav.tsx
'use client'

import React, { useState, useEffect } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { Button } from "@/components/ui/button";
import { ChevronDown, Megaphone, DollarSign, Cog, Monitor } from 'lucide-react';
import { SignUpButton, useUser } from "@clerk/nextjs";
import AuthNav from "@/components/AuthNav";
import { Typewriter } from 'react-simple-typewriter';

export default function HomePage() {
  const [scrolled, setScrolled] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const { isSignedIn, isLoaded } = useUser();
  const router = useRouter();

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > window.innerHeight * 0.5);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    // Only redirect if we're sure about the authentication state
    if (isLoaded && isSignedIn && !isRedirecting) {
      setIsRedirecting(true);
      // Add a small delay to ensure smooth transition
      setTimeout(() => {
        router.push('/Chat2');
      }, 100);
    }
  }, [isLoaded, isSignedIn, router, isRedirecting]);

  // Show loading state while Clerk is initializing
  if (!isLoaded) {
    return (
      <div className="min-h-screen bg-[#00122A] flex items-center justify-center">
        <div className="animate-pulse text-white text-xl">Loading...</div>
      </div>
    );
  }

  // Show loading state during redirect
  if (isRedirecting || (isLoaded && isSignedIn)) {
    return (
      <div className="min-h-screen bg-[#00122A] flex items-center justify-center">
        <div className="animate-pulse text-white text-xl">Redirecting to dashboard...</div>
      </div>
    );
  }

  const teamCards = [
    { name: 'Marketing', icon: Megaphone, description: 'Drive high-quality leads and more inbound sourced revenue with effective lead generation' },
    { name: 'Sales', icon: DollarSign, description: 'Close more deals faster with automated lead routing and contract management.' },
    { name: 'Operations', icon: Cog, description: 'Unify systems and share data across all tools to prevent errors and the loss of company data.' },
    { name: 'IT', icon: Monitor, description: 'Avoid security breaches and minimize disruptions by identifying incidents immediately.' },
  ];

  return (
    <div className="min-h-screen bg-[#00122A] text-white">
      <header className={`fixed w-full z-10 transition-colors duration-300 ${scrolled ? 'bg-[#00122A]/90 backdrop-blur-sm' : 'bg-transparent'}`}>
        <div className="container mx-auto px-4 py-4 flex justify-between items-center">
          <Image 
            src="/solyo_Icon.png" 
            alt="Solyo Logo" 
            width={100} 
            height={40} 
            className="border border-black-500"
            priority // Add priority to load logo faster
          />

          <nav className="hidden md:flex space-x-4">
            <a href="#" className="hover:text-purple-300">Products</a>
            <a href="#" className="hover:text-purple-300">Use Cases</a>
            <a href="#" className="hover:text-purple-300">Pricing</a>
            <a href="#" className="hover:text-purple-300">Resources</a>
          </nav>
          
          <AuthNav />
        </div>
      </header>

      <section className="h-screen flex flex-col justify-center items-center text-center px-4 bg-gradient-to-br from-[#00122A] to-[#003366]">
        <h1 className="text-6xl font-bold mb-4">Expertise Automated</h1>
        <h2 className="text-6xl font-bold mb-4 text-[#B9FF4D] h-24">
          <Typewriter
            words={[
              'Email Summary',
              'Calendar Assistant',
              'Contract Validation',
              'Chat with any document',
              'Automate your blogs',
              'Trip Planner'
            ]}
            loop={0}
            cursor
            cursorStyle='|'
            typeSpeed={70}
            deleteSpeed={50}
            delaySpeed={1000}
          />
        </h2>
 
        <p className="text-xl mb-8 max-w-4xl">Save Time! SuperCharge your productivity with a crew of personal assistants.</p>
        
        <SignUpButton mode="modal">
          <Button className="bg-[#B9FF4D] hover:bg-[#CFFF80] text-black">
            Get started free
          </Button>
        </SignUpButton>
        
        <div className="mt-4 text-sm">
          <span className="mr-4">✓ No credit card required</span>
        </div>
        <ChevronDown className="absolute bottom-8 animate-bounce" size={32} />
      </section>
    </div>
  );
}






{/*
  
  Dark Blue (#003366)
#4D6980 (Lightest)
#335270
#002F55
#001F3A
#00122A (Darkest)


Lightest Turquoise: #4DC6CF
Turquoise: #33A3AF
Medium Turquoise: #008D99
Deep Turquoise: #00737F
Darkest Turquoise: #005963
#4DC6CF


Silver (#C0C0C0)
#FFFFFF (Lightest)
#E6E6E6
#B3B3B3
#808080
#4D4D4D (Darkest)

Fuchsia (#FF00FF)
#FF99FF (Lightest)
#FF66FF
#FF33FF
#CC00CC
#990099 (Darkest)


Orange (#FFA500)
#FFD199 (Lightest)
#FFC266
#FFB233
#E69500
#CC8400 (Darkest)

Green Yellow (#ADFF2F)
#E5FFB3 (Lightest)
#CFFF80
#B9FF4D
#994E62
#7ABF23 (Darkest)


Dark Gray (#333333)
#666666 (Lightest)
#4D4D4D
#404040
#1A1A1A
#0D0D0D (Darkest)


  
  */}

  {/*
      <section className={`py-20 bg-white text-black transition-opacity duration-500 ${scrolled ? 'opacity-100' : 'opacity-0'}`}>
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center">More than no-code workflow automation</h2>
          <p className="text-xl mb-12 text-center">Traditional no-code iPaaS platforms are linear and non-intuitive. Make allows you to visually create, build, and automate without limits.</p>
          <div className="flex justify-center items-center space-x-4">
            <Button variant="outline" size="lg" className="rounded-full">See Make in action</Button>
            <div className="w-32 h-32 bg-purple-200 rounded-full flex items-center justify-center">
              <ChevronDown size={48} className="text-purple-600" />
            </div>
          </div>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8 text-center">One platform. Unlimited possibilities for all.</h2>
          <p className="text-xl mb-12 text-center">Boost productivity across every area or team. Anyone can use Make to design powerful workflows without relying on developer resources.</p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
            {teamCards.map(({ name, icon: Icon, description }) => (
              <Card key={name} className="p-6 bg-[#3d2a5e]">
                <div className="flex flex-col items-center mb-4">
                  <Icon size={48} className="text-[#ff3ef7] mb-2" />
                  <h3 className="text-2xl font-bold">{name}</h3>
                </div>
                <p className="text-sm text-center">{description}</p>
              </Card>
            ))}
          </div>
        </div>
      </section>

      <section className="py-20 bg-[#f5e6ff] text-black">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8">Automate your work. Build something new.</h2>
          <p className="text-xl mb-12">Just drag and drop apps to automate existing workflows or build new complex processes. Solve problems across all areas and teams.</p>
          <Button size="lg">Get started</Button>
        </div>
      </section>

      <section className="py-20">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-8">Start with a template</h2>
          <p className="text-xl mb-12">Get inspired by thousands of free workflow automation templates. Customize them to match your ideal workflows.</p>
          <Button size="lg" variant="outline">Choose a template</Button>
        </div>
      </section>

*/}




