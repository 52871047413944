//frontend/solyo/components/AuthNav.tsx
import React from 'react';
import { SignInButton, SignUpButton } from "@clerk/nextjs";
import { Button } from "@/components/ui/button";

interface AuthNavProps {
  className?: string;
  variant?: 'default' | 'ghost' | 'outline';
}

const AuthNav: React.FC<AuthNavProps> = ({ 
  className = '', 
  variant = 'default' 
}) => {
  return (
    <div className={`flex items-center space-x-2 ${className}`}>
      <SignInButton mode="modal">
        <Button variant={variant === 'default' ? 'outline' : variant}>
          Log in
        </Button>
      </SignInButton>
      <SignUpButton mode="modal">
        <Button 
          className={variant === 'default' ? 'bg-[#B9FF4D] hover:bg-[#CFFF80] text-black' : ''}
          variant={variant !== 'default' ? variant : 'default'}
        >
          {variant === 'default' ? 'Get started for free' : 'Sign up'}
        </Button>
      </SignUpButton>
    </div>
  );
};

export default AuthNav;